<template>
  <div>
    <div
      class="topBox"
      v-for="(bigItem, bigIndex) in GoodsList"
      :key="bigIndex"
    >
      <div class="box1">
        <div class="linebox">
          <img class="" :src="img" alt="" />
          <p class="title">
            {{ bigItem.name }}
          </p>
          <p class="num">x{{ bigItem.num }}</p>
        </div>
      </div>
      <div
        v-for="(item, index) in bigItem.data"
        :key="index"
        :class="index !== 0 ? 'product-wrap' : ' product-wrap2'"
      >
        <div class="productTop">
          <div class="item1">
            <div class="item1Img">
              <!-- ../../../assets/shoppingCart/product.png -->
              <!-- <img :src="item.materialGroupId+'/'+item.productId+'/180-180/'+item.picture" alt /> -->
              <h-img
                class="img"
                :imgUrl="item.img"
                :errorImg="item.defaultImg"
                :isUseBasePath="true"
              ></h-img>
            </div>
            <div class="item1Val">
              <div class="item1Title">
                <p v-if="item.productName">
                  <span  v-if="item.productLabel && item.productLabel.indexOf('15691143850')!==-1" class="freeShipping">免运费</span>
                  <span  v-if="item.productLabel && item.productLabel.indexOf('15691143849')!==-1" class="bright">璀璨</span>
                  <span  v-if="item.productLabel && item.productLabel.indexOf('14182273113')!==-1" class="only">专供</span>
             
                  {{ item.productName }}</p>
              </div>
              <p>
                <span style="margin-right:12px;">品牌：{{ item.brand }}</span>
                <span v-if="item.color">颜色：{{ item.color }}</span>
              </p>
              <p>体积：{{ item.volume }}m³</p>
            </div>
          </div>
          <div class="item2">
            <div class="item2Title">
              ￥{{ $util.getFloat(item.billPrice, 2) }}
              <span style="margin-left:5px;" v-if="item.standardPrice"
                >￥{{ $util.getFloat(item.standardPrice, 2) }}</span
              >
            </div>
            <div class="item2Item">
              <!-- <p>固定折扣：{{item.fixedDiscount}}%</p> -->
              <p>定价组：{{ item.priceGroupName }}</p>
<!--              <p>补差类型：{{ item.makeUpTypeName }}</p>-->
              <!-- <p>单台返利30元</p> -->
              <div>
                <p>定金：￥{{ Number(item.deposit).toFixed(2) }}</p>
                <p v-show="item.rebateMoney&&item.rebateMoney>0">返利金：{{ Number(item.rebateMoney).toFixed(2) }}</p>
              </div>
            </div>
            <div class="rebate-div" v-if="item.isShowRebate">最高可享台返{{Number(item.returnAmount || 0).toFixed(2)}}元，政策后提货价{{Number(item.billPrice - (item.returnAmount || 0)).toFixed(2)}}元，限量{{item.upperLimit || 999}}台</div>
          </div>
          <div class="item3">
            <!-- <div class="item3Title">x{{ item.buyQty }}</div> -->
            <div class="item3Title">
              <number-box
                @getNumber="dataChange(item, $event)"
                :curVal.sync="item.buyQtyCherk"
                :minVal="0"
                :maxVal="item.buyQty"
                style="margin-left:-92px"
              >
              </number-box>
            </div>
            <div class="item3Item">
              <div class="item3Items">
                <!-- <p v-if="item.customerCode">海信库存:{{item.customerCode}}</p>
            <p v-if="item.shareOrgFlag">共享库存:{{item.shareOrgFlag}}</p>-->
              </div>
              <!-- <div class="item3Items">
            <p>待总部评审(20)</p>
            <p>待发货(10)</p>
            <p>已商收(24)</p>
            <p>待发货(20)</p>
            <p>甩货(10)</p>
          </div>
          <p>已取消(34)</p>-->
            </div>
          </div>
<!--          <div class="item4">-->
<!--            <div class="item4Title">-->
<!--              小计：￥{{ $util.getFloat(item.billPrice * item.buyQtyCherk, 2) }}-->
<!--            </div>-->
<!--            &lt;!&ndash; 采购订单详情取消 &ndash;&gt;-->
<!--            &lt;!&ndash; <p class="purchase" @click="cancleClick(item)">取消</p> &ndash;&gt;-->
<!--          </div>-->
          <div class="item4" v-if="trans !='502004'&&trans!='502008'">
            <div class="item4Title">小计：￥{{$util.getFloat(item.billPrice*item.buyQtyCherk,2)}}</div>
          </div>
          <div class="item4" v-if="(trans=='502004'||trans=='502008')&& !haveDeliveryFee">
            <div class="item4Title">小计：￥{{$util.getFloat(item.billPrice*item.buyQtyCherk,2)}}</div>
            <div class="purchase" style="margin-top:6px;line-height: 16px;font-size:12px" >
              <div class="price">零售价格：￥
                <span :style="{color:Number(item.salePrice)>0?'#262626':'#777'} "
                      v-if="!item.idEdit">{{Number(item.salePrice) && Number(item.salePrice)>0?item.salePrice:'请输入'}}
              </span>
                <a-input oninput="this.value=this.value.match(/\d+\.?\d{0,2}/)" type="text" v-if="item.idEdit" @blur="blurPrice(item)" @change="changePrice(item,$event)" style="width: 120px" v-model="item.salePrice" />
              </div>
              <span @click="edit(item)" class="edit">{{item.idEdit?'完成':'编辑'}}</span>
            </div>
          </div>
          <div class="item4" v-if="(trans=='502004'||trans=='502008')&&haveDeliveryFee">
            <div class="item4Title">小计：</div>
            <p style="margin-top:8px;line-height: 16px;font-size:12px">货款:￥{{$util.getFloat(item.billPrice*(item.packageNum || item.buyQtyCherk),2)}}</p>
            <p style="margin-top:8px;line-height: 16px;font-size:12px">服务费:￥{{item.serviceMoney?Number(item.serviceMoney).toFixed(2):'0.00'}}</p>
            <div class="purchase" style="margin-top:6px;line-height: 16px;font-size:12px" >
              <div class="price">零售价格：￥
                <span :style="{color:Number(item.salePrice)>0?'#262626':'#777'} "
                      v-if="!item.idEdit">{{Number(item.salePrice) && Number(item.salePrice)>0?item.salePrice:'请输入'}}
              </span>
                <a-input oninput="this.value=this.value.match(/\d+\.?\d{0,2}/)" type="text" v-if="item.idEdit" @blur="blurPrice(item)" @change="changePrice(item,$event)" style="width: 120px" v-model="item.salePrice" />
              </div>
              <span @click="edit(item)" class="edit">{{item.idEdit?'完成':'编辑'}}</span>
            </div>

            <!-- 采购订单详情取消 -->
            <!-- <p class="purchase" @click="cancleClick(item)">取消</p> -->
          </div>

        </div>
        <!-- 通用结算页面显示内容 活动 -->
        <!-- v-if="settlement" -->
        <!-- item.preferCode -->
<!--        <div v-if="item.activityName">-->
<!--          <div class="line"></div>-->
<!--          <div class="settlement">-->
<!--            <div class="zhengce">-->
<!--              <div>-->
<!--                <a-radio :checked="true"></a-radio>-->
<!--              </div>-->
<!--              <div class="info">-->
<!--                <div class="settleTitle">{{ item.activityName }}</div>-->
<!--                <div class="settlementVal">-->
<!--                  &lt;!&ndash; <p>批次：{{item.batch}}</p> &ndash;&gt;-->
<!--                  <p>标准价：￥{{ $util.getFloat(item.standardPrice, 2) }}</p>-->
<!--                  <p>直扣：￥{{ $util.getFloat(item.deduct, 2) }}</p>-->
<!--                  <p>开票价：￥{{ $util.getFloat(item.billPrice, 2) }}</p>-->
<!--                  <p>总数量：{{ item.qty }}</p>-->
<!--                  <p>-->
<!--                    可购数量：{{-->
<!--                      item.purchaseLimitQty - item.userAlreadyOrderNum-->
<!--                    }}-->
<!--                  </p>-->
<!--                  <p>已购数量：{{ item.userAlreadyOrderNum }}</p>-->
<!--                  &lt;!&ndash; <p>单个商家限购数量：{{item.purchaseLimitQty}}</p> &ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductItem",
  props: {
    item: {
      required: false
    },
    settlementItem: {
      required: false
    },
    GoodsList: {
      type: Array,
      required: true
    },
    trans:{
      required: true
    },
    haveDeliveryFee: {
      required: true
    },
  },
  data() {
    return {
      makeShure: true,
      //   dataArr: [],
      img: require("././../../../assets/noticeList/编组 13@2x.png")
    };
  },
  mounted() {
  },
  watch:{
    GoodsList: {
      handler: function(val, oldVal) {
        val.forEach(it=>{
          it.data.forEach(el => {
            el.idEdit = false
          })
        })

      },
      // 深度观察监听
      deep: true
    },
    trans: {
      handler: function(val, oldVal) {
      },
      // 深度观察监听
      deep: true
    },
    item: {
      handler: function(val, oldVal) {
      },
      // 深度观察监听
      deep: true
    },
    haveDeliveryFee: {
      handler: function(val, oldVal) {
      },
      // 深度观察监听
      deep: true
    },
  },
  methods: {
    edit(item) {
      item.idEdit = !item.idEdit
      this.$forceUpdate()
    },
    changePrice(item,e){
      this.$emit("changePrice", item);
    },
    blurPrice(item,e){
      item.salePrice=item.salePrice.replace(/[^\-?\d.]/g,'')
      item.salePrice= Number(item.salePrice).toFixed(2)
      if (item.type == 1 && item.salePrice) {
        if (Number(item.salePrice) < item.min || Number(item.salePrice) > item.max) {
          if (item.haveRetailL) {
            this.$message.warning(`请在默认建议零售价的${item.bei}倍范围内录入价格`);
          } else {
            this.$message.warning(`您录入的价格不符合产品公司规定，请按要求录入。`);
          }
          item.salePrice = item.retailPrice;
          this.$forceUpdate()
          return;
        }
      }
      this.$emit("changePrice", item);
    },
     dataChange(item, event) {
      this.$emit("dataChange", event);
      item.buyQtyCherk = event
      this.$forceUpdate();
      
    },
    // changeOpened(item) {
    //   this.$set(item, "opened", !item.opened);
    //   // item.opened = !item.opened
    // },
    // // 点击取消按钮
    cancleClick(data) {
      this.$emit("cancleClick", data);
    },
    // 特购页面点击是否需要特购优惠
    handleChange(data) {
      this.makeShure = !this.makeShure;
      this.$emit("settleChose", data);
    },
    // 点击购物车
    carClick() {
      this.$emit("carClick", "");
    }
  }
};
</script>

<style scoped lang="less">
.topBox {
  position: relative;
  .box1 {
    position: absolute;
    // top: 0;
    left: 160px;
    // right: ;
    margin: 0 auto;
    margin-top: -2px;
    .linebox {
      position: relative;
      width: 1156px;
      height: 34px;
      left: -168px;
      // background: #F2F3F5;
      // background-image: url('./../../assets/noticeList/编组 13@3x.png');
      img {
        width: 100%;
        height: 100%;
      }
      .title {
        position: absolute;
        color: #262626;
        font-size: 14px;
        top: 2px;
        left: 76px;
        font-weight: 500;
      }
      .num {
        position: absolute;
        top: 3px;
        right: 76px;
        width: 24px;
        height: 20px;
        border-radius: 4px;
        border: 1px solid #777777;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #262626;
        font-weight: 500;
      }
    }
  }
}
.product-wrap {
  width: 1140px;
  padding: 23px 0 20px 24px;
  margin-top: 16px;
  box-sizing: border-box;
  text-align: left;
  background: #F7F7F7;
  border-radius: 8px;
  .productTop {
    display: flex;
    justify-content: flex-start;
    .item1 {
      width: 392px;
      height: 100px;
      display: flex;
      justify-content: flex-start;
      .item1Img {
        margin-right: 16px;
        width: 100px;
        height: 100px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        .img{
          width: 80px!important;
          height: 80px!important;
        }
      }
      .item1Val {
        text-align: left;
        padding-top: 5px;
        box-sizing: border-box;
        .item1Title {
          display: flex;
          justify-items: flex-start;
          align-items: center;
          .freeShipping {
            margin-left: 0px;
            font-size: 12px;
            color: #fff;
            width: 42px;
            text-align: center;
            background: linear-gradient(90deg, #FF7C45 0%, #F63737 100%);
            border-radius: 2px;
            font-weight: 400;
            margin-right: 4px;
            height: 16px;
            line-height: 16px;
          }
          .bright {
            font-size: 12px;
            color: #433538;
            width: 30px;
            height: 16px;
            text-align: center;
            line-height: 16px;
            background: linear-gradient(90deg, #D49879 0%, #FEF2DC 50%, #D49879 100%);
            border-radius: 2px;
            margin-right: 4px;
          }
          .only{
              width: 30px;
              height: 16px;
              background: linear-gradient( 90deg, #54D6D2 0%, #00AAA6 100%);
              border-radius: 2px;
              font-size: 12px;
              color: #FFFFFF;
              text-align: center;
              line-height: 16px;
            }
          p {
            width: 240px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            color: #262626;
            line-height: 20px;
            margin-top: 0;
          }
          .buyCar {
            width: 28px;
            height: 28px;
            border-radius: 14px;
            background: #ecf8f8;
            text-align: center;
            cursor: pointer;
            img {
              width: 16px;
              height: 16px;
              margin-top: 6px;
            }
          }
        }
        p {
          margin-top: 8px;

          font-size: 12px;
          color: #777;
          line-height: 16px;
        }
      }
    }
    .item2 {
      width: 210px;
      margin-right:44px;
      position: relative;
      .item2Title {
        width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-wrap: nowrap;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
        padding-top: 5px;
        box-sizing: border-box;
        span {
          text-decoration: line-through;
          font-size: 12px;
          color: #777;
        }
      }
      .item2Item {
        font-size: 12px;
        color: #777;
        line-height: 16px;
        display: flex;
        flex-direction: column;
         div {
           display: flex;
           flex-direction: row;
         }
        p {
          margin-right: 10px;
          margin-top: 8px;
        }
      }
      .rebate-div{
        height: 16px;
        line-height: 16px;
        color: #FC5260;
        font-size: 12px;
        position: absolute;
        top: 81px;
        left: 0;
        width: 400px;
      }
    }
    .item3 {
      width: 130px;
      margin-left: 24px;
      .item3Title {
        padding-top: 5px;
        box-sizing: border-box;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
      }
      .item3Item {
        font-size: 12px;
        color: #777;
        line-height: 16px;

        p {
          margin-top: 8px;
        }
        .item3Items {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          p {
            margin-right: 10px;
          }
        }
      }
    }
    .item4 {
      width: 250px;
      padding-top: 5px;
      box-sizing: border-box;
      margin-left: 24px;
      .item4Title {
        font-size: 14px;
        color: #262626;
        line-height: 20px;
      }
      .purchase {
        //cursor: pointer;
        //margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        display: flex;
        flex-direction: row;
        align-items: center;
        .price{
          display: flex;
          flex-direction:row;
          align-items: center;
          font-size:12px
        }
        .edit {
          cursor: pointer;
          color: #1890FF;
          margin-left: 16px;
          font-size:12px
        }
      }

    }
  }

  .line {
    width: 1092px;
    height: 1px;
    // border: 1px solid #eeeeee;
    background: #eee;
    margin-top: 22px;
  }
  .settlement {
    width: 100%;
    // height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding-left: 49px;
    box-sizing: border-box;
    .zhengce {
      // width: 500px;
      // height: 96px;
      padding: 0 0 5px;
      border-radius: 4px;
      // border: 1px solid #fd6a6e;
      margin-top: 12px;
      margin-bottom: 0;
      // background-image: url(/img/right.ab515ecb.png);
      // background-position: right bottom;
      // background-size: 26px 19px;
      // background-repeat: no-repeat;
      display: flex;
      align-items: center;
      .info {
        .settleTitle {
          font-size: 14px;
          color: #777777;
          line-height: 16px;
          margin-left: 24px;
          margin-bottom: 8px;
          margin-top: 16px;
        }
        .settlementVal {
          width: 679px;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-left: 24px;
          p {
            width: 150px;
            // padding: 0 5px;
            text-align: left;
            margin-right: 20px;
            margin-top: 8px;
            font-size: 12px;
            color: #777;
            line-height: 16px;
          }
        }
      }
    }
  }
}
.product-wrap2 {
  width: 1140px;
  padding: 23px 0 20px 24px;
  margin-top: 16px;
  box-sizing: border-box;
  text-align: left;
  background: #F7F7F7;
  border-radius: 8px;
  padding-top: 44px;
  .productTop {
    display: flex;
    justify-content: flex-start;
    .item1 {
      width: 392px;
      height: 100px;
      display: flex;
      justify-content: flex-start;
      .item1Img {
        margin-right: 16px;
        width: 100px;
        height: 100px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        .img{
          width: 80px!important;
          height: 80px!important;
        }
      }
      .item1Val {
        text-align: left;
        padding-top: 5px;
        box-sizing: border-box;
        .item1Title {
          display: flex;
          justify-items: flex-start;
          align-items: center;
          .freeShipping {
            margin-left: 0px;
            font-size: 12px;
            color: #fff;
            width: 42px;
            text-align: center;
            background: linear-gradient(90deg, #FF7C45 0%, #F63737 100%);
            border-radius: 2px;
            font-weight: 400;
            margin-right: 4px;
            height: 16px;
            line-height: 16px;
          }
          .bright {
            font-size: 12px;
            color: #433538;
            width: 30px;
            height: 16px;
            text-align: center;
            line-height: 16px;
            background: linear-gradient(90deg, #D49879 0%, #FEF2DC 50%, #D49879 100%);
            border-radius: 2px;
            margin-right: 4px;
          }
          .only{
              width: 30px;
              height: 16px;
              background: linear-gradient( 90deg, #54D6D2 0%, #00AAA6 100%);
              border-radius: 2px;
              font-size: 12px;
              color: #FFFFFF;
              text-align: center;
              line-height: 16px;
            }
          p {
            width: 240px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            color: #262626;
            line-height: 20px;
            margin-top: 0;
          }
          .buyCar {
            width: 28px;
            height: 28px;
            border-radius: 14px;
            background: #ecf8f8;
            text-align: center;
            cursor: pointer;
            img {
              width: 16px;
              height: 16px;
              margin-top: 6px;
            }
          }
        }
        p {
          margin-top: 8px;

          font-size: 12px;
          color: #777;
          line-height: 16px;
        }
      }
    }
    .item2 {
      width: 210px;
      margin-right:44px;
      position: relative;
      .item2Title {
        width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-wrap: nowrap;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
        padding-top: 5px;
        box-sizing: border-box;
        span {
          text-decoration: line-through;
          font-size: 12px;
          color: #777;
        }
      }
      .item2Item {
        font-size: 12px;
        color: #777;
        line-height: 16px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        p {
          margin-right: 10px;
          margin-top: 8px;
        }
      }
      .rebate-div{
        height: 16px;
        line-height: 16px;
        color: #FC5260;
        font-size: 12px;
        position: absolute;
        top: 57px;
        left: 0;
        width: 400px;
      }
    }
    .item3 {
      width: 130px;
      margin-left: 24px;
      .item3Title {
        padding-top: 5px;
        box-sizing: border-box;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
      }
      .item3Item {
        font-size: 12px;
        color: #777;
        line-height: 16px;

        p {
          margin-top: 8px;
        }
        .item3Items {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          p {
            margin-right: 10px;
          }
        }
      }
    }
    .item4 {
      width: 250px;
      padding-top: 5px;
      box-sizing: border-box;
      margin-left: 24px;
      .item4Title {
        font-size: 14px;
        color: #262626;
        line-height: 20px;
      }
      .purchase {
        //cursor: pointer;
        //margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        display: flex;
        flex-direction: row;
        align-items: center;
        .price{
          display: flex;
          flex-direction:row;
          align-items: center;
        }
        .edit {
          cursor: pointer;
          color: #1890FF;
          margin-left: 16px;
        }
      }

    }
  }

  .line {
    width: 1092px;
    height: 1px;
    // border: 1px solid #eeeeee;
    background: #eee;
    margin-top: 22px;
  }
  .settlement {
    width: 100%;
    // height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding-left: 49px;
    box-sizing: border-box;
    .zhengce {
      // width: 500px;
      // height: 96px;
      padding: 0 0 5px;
      border-radius: 4px;
      // border: 1px solid #fd6a6e;
      margin-top: 12px;
      margin-bottom: 0;
      // background-image: url(/img/right.ab515ecb.png);
      // background-position: right bottom;
      // background-size: 26px 19px;
      // background-repeat: no-repeat;
      display: flex;
      align-items: center;
      .info {
        .settleTitle {
          font-size: 14px;
          color: #777777;
          line-height: 16px;
          margin-left: 24px;
          margin-bottom: 8px;
          margin-top: 16px;
        }
        .settlementVal {
          width: 679px;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-left: 24px;
          p {
            width: 150px;
            // padding: 0 5px;
            text-align: left;
            margin-right: 20px;
            margin-top: 8px;
            font-size: 12px;
            color: #777;
            line-height: 16px;
          }
        }
      }
    }
  }
}
</style>