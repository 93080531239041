import Step from '@/components/global/Step/Step.vue'
import ProductItem from './components/ProductItem.vue'
import groupProductItem from "./components/groupProductItem"
import {
  // orderWeek,
  // 购物车查询
  // orderCar,
  // 统仓联系人
  // orderAddress,
  // 分销商 分销地址
  showAnother,
  // 获取城市
  cityChange,
  // 获取城镇 区
  townChange,
  // 获取城镇区下一级
  getQu,
  // 分销商切换 获取新分销商地址
  getDISAdd,
  // 分销商地址切换请求联系人
  getPhone,
  // 要求到货周
  // getWeek,
  // 商品列表
  //goodsList,
  // 获取商品列表库存数
  getNumber,
  // 购物车提交
  // cartSubmit,
  // 特惠订单提交
  specialSubmit,
  // 查看预占用额度明细
  quotaDeatail,
  // 特惠专区接口
  specialZone,
  // 充值
  saveMoney,
  // 充值记录
  saveHisetory,
  serviceInformation,
  SalesShopInfoList
} from './api';
import {
  saveMoneyType
} from "@/common/constant";
import { checkShipTo, getAdressapi } from "@/views/settlement/api";
import moment from "moment";
import addUserSource from "@/views/userOperations/IntendedUserEntry/components/addUserSource";
import addUserTips from "@/views/userOperations/IntendedUserEntry/components/addUserTips";
import { findMoneyBuyBegin } from "@/views/specialZone/api";
import { findList, findListLabel, saveInfo, saveInfoLabel } from "@/views/userOperations/IntendedUserEntry/api";
import { ctsApi, fxZsyhGetDeliveryMethod } from "@/views/channel/api";
import { getRetailPrice } from "@/views/stockCenter/adjustmentApply/components/api";
import { checkRetailL } from "@/views/channelInput/saleNew/api";
import { getUserList } from "@/views/userOperations/IntentionList/api";
import addressModal from "@/views/settlement/components/addressModal";

export default {
  name: "Home",
  components: {
    Step,
    ProductItem,
    groupProductItem,
    addUserSource,
    addUserTips,
    addressModal
  },
  data() {
    return {
      modelShow: false,
      visibleAddress:false,
      newTown:'',
      // 查看占用明细列表
      columns: [{
        title: '销售组织',
        dataIndex: 'orgName',
        key: 'orgName',
        width: 100
      },
      {
        title: '物料组',
        dataIndex: 'matklName',
        key: 'amatklNamege',
        width: 100
      },
      {
        title: '订单编码',
        dataIndex: 'orderCode',
        key: 'orderCode',
        width: 150
      },
      {
        title: '订单类型',
        dataIndex: 'orderType',
        key: 'orderType',
        width: 100
      },
      {
        title: '订单状态',
        dataIndex: 'status',
        key: 'status',
        width: 100
      },
        {
          title: '商品型号&数量',
          dataIndex: 'productAndQtys',
          key: 'productAndQtys',
          width:250,
          ellipsis: true,
        },
      {
        title: '付款状态',
        dataIndex: 'fkStatus',
        key: 'fkStatus',
        width: 100
      }, {
        title: '订单金额',
        dataIndex: 'orderAmt',
        key: 'orderAmt',
        align: 'right',
        width: 100
      }, {
          title: '预占用金额',
          dataIndex: 'waitBalance',
          key: 'waitBalance',
          width:120,
          align: 'right',
          scopedSlots: { customRender: "type" },
        }, {
        title: '要求到货周次',
        dataIndex: 'weekName',
        key: 'weekName',
        width: 200
      },
      {
        title: '下单日期',
        dataIndex: 'createdDate',
        key: 'createdDate',
        width: 150
      },
      ],
      // 查看占用明细列表
      data: [],
      bankChecked: true,
      visible: false,
      steps: ['选择商品', '确认订单信息', '提交订单'],
      currentStep: 2,
      options: [],
      other: '',
      rules: {
        name: [{
          required: true,
          message: '请输入联系人',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 5,
          message: 'Length should be 3 to 5',
          trigger: 'blur'
        }
        ],
        phone: [{
          required: true,
          message: '请输入联系方式',
          trigger: 'blur'
        },
        {
          min: 7,
          max: 11,
          message: '请输入正确的联系方式',
          trigger: 'blur'
        },
        ],
        billName: [{
          required: true,
          message: '请选择开票户头',
          trigger: 'change'
        }],
        address: [{
          required: true,
          message: '请选择收货地址',
          trigger: 'change'
        }],
        agency: [{
          required: true,
          message: '请选择海信办事处',
          trigger: 'change'
        }],
        askWeekend: [{
          required: true,
          message: '请选择周',
          trigger: 'change'
        }],
        distributor: [{
          required: true,
          message: '请选择分销商',
          trigger: 'change'
        }],
        distributorAddress: [{
          required: true,
          message: '请选择分销商地址',
          trigger: 'change'
        }],
        date1: [{
          required: true,
          message: 'Please pick a date',
          trigger: 'change'
        }],
        type: [{
          type: 'array',
          required: true,
          message: 'Please select at least one activity type',
          trigger: 'change',
        },],
        resource: [{
          required: true,
          message: 'Please select activity resource',
          trigger: 'change'
        },],
      },
      // 联系人
      contacts: '',
      // 联系人手机号
      phoneCon: '',
      // 开票户头账号
      choseAccounts: [],
      // 是否显示其他开票户头
      accountShow: false,
      // 开票户头
      invoice: '',
      // 开票户头id
      invoiceId: '',
      //是否允许提前发货
      allowAdvance: true,
      // 用户选择截止时间
      // endTime: '',
      // 控制分销商及分销商地址是否显示
      distributors: false,
      // 经销商下拉选择框
      distr: false,
      // 经销商地址
      distrAddress: '',
      distrAdd: false,
      distrName: '',
      // 经销商地址
      orderDis: [],
      // 选择直配到分销时填写客户单号
      orderNumber: '',
      // 供应商为海信时显示供应商为物料组
      suppilers: '',
      // 配送方式选择直配到用户
      userAdderss: false,
      // 用户详细地址
      userDetailAddress: '',
      secondCity: '',
      // 接收子组件选中的商品
      sonChectItme: [],
      receiveAdd: false,
      // 收货地址/统仓地址
      receive: '',
      // 收货地址id
      receiveID: '',
      // 收货地址类型 obj.shareFlag=='Y'?'统仓地址':'收货地址'
      houseAddress: '收货地址',
      // 统仓联系人禁止修改
      TCInt: false,
      // 配送方式ID
      sendId: '',
      // 分销商地址选择
      distributionAddress: [],
      // 输入输入内容
      searchInt: '',
      // 省
      provices: [],
      //市
      getCity: [],
      // 城镇
      getTown: [],
      // 城镇下一级
      qu: [],
      // 省市区选择
      // proObj: {},
      // 开票户头
      billToDTOs: [],
      // 经销商地址列表
      choseDistribution: [],
      // 分销商列表
      DisList: [],
      // 经销商及经销商地址
      distributionAdd: {
        DISCODE: '',
        DISADD: ''
      },
      // 经销商切换获取地址 接口参数
      DISADDPARAMS: {
        customerId: '',
        orgId: '',
        matklId: ''
      },
      // 要求到货周
      arriveOptions: [],
      selected: '',
      GoodsList: [],
      // 商品数量、体积、价格、预付款
      goodsNum: 0,
      goodsVolume: 0,
      goodsPrice: 0,
      advancePrice: 0,
      TotalPayment: {},
      // 预付比例
      advancePayRate: 0,
      totalAdvanceMoney: 0,
      drawerName: '',
      dissize: false,
      // 点击提交按钮提交的参数
      orgAndGroup: '',
      orderCartIds: {},
      // 配送方式
      trans: '',
      hisenseOff: false,
      officehis: [],
      officeCode: '',
      receivehis: '',
      officeHis: false,
      orgCode: '',
      matklId: '',
      orgId: '',
      orgtitle: '',
      obj: {},
      // 提交给后台订单详情
      submitGoods: [],
      endTime: '',
      // 有效截止时间
      startTime: '',
      // 后台接口最大截止时间
      maxEndDate: '',
      // 面包屑
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
      {
        path: "/activity",
        name: "activity",
        title: "活动专区"
      }, {
        path: "/activity",
        name: "activityZone",
        title: "专区结算"
      }
      ],
      // 上个页面传过来的参数
      RouterParams: {
        prdIds: '14170722580',
        buyNums: '1'
      },
      // 按钮组件loading
      isLoading: false,
      // 预占用明细分页组件
      pager: {
        count: '',
        pageNo: 1,
        pageSize: 10
      },
      // 是否可以使用返利
      tradeType: false,
      saveMoneyValue: {},
      URLTO: '',
      pageLoadFlag: true,
      saleShopList: [], // 销量所属门店
      receiveShop: '', // 销量所属门店名称
      salesShopInfoId: '',  // 销量所属门店id
      fwlbId: [],
      // 服务列表数据
      FWlist: [],
      provinceCode: '',//省
      cityCode: '',//市
      districtCode: '',//区
      townCode: '',//县乡
      dataArr: [],
      activeType: '',
      quantityControl:'',// 按数量下单还是安比例下单标识  1 按数量 0 按比例
      companyList:[],
      fetching:false,
      addressHx:[],
      //返利按钮是否选中
      isTradeChecked: true,
      arrivalTimeStr:'',
      activityName:'',
      visibleaA: false,// 新建用户来源弹窗
      visibleTips: false,// 新建用户标签弹窗
      userSorceList: [],
      SorceIds: '',// 选中的用户来源
      userTipsList: [],
      TipsIds: [],// 选中的标签
      TipsNames: [],// 选中的标签
      SelectItem:[],
      isAddressSupport:'',
      sexId:1,
      sexoptions: [{id: 1, name: '男士'}, {id: 2, name: '女士'}], // 用户性别列表
      serviceCharge:0,
      orderServiceFeeItemDtoList:[],
      warehouseInfoId:'',
      isShowLogisticsIcon:false,
      isChooseNum:0,
      isChooseNumStart:0,
      phone:'',
      text1:'',
      text2:'',
      haveDeliveryFee:false,
      transDefault:'',
      showSorce:false,
      sourceName:'',
      endOpen:false,
      startShowTime:'',
      addressDftVal:[], // 收货地址回填的值
      brightNum:0,// 璀璨数量
      brightPrice:0,//璀璨价格
      olnyNum:0,// 专供数量
      olnyPrice:0,//专供价格
    };
  },

  mounted() {
    this.RouterParams = this.$route.query;
    // 购物车查询
    this.carList()
    this.URLTO = saveMoneyType
    this.getuserSorceList()
    this.getuserTipList()
    const data1 = this.$getAlertInfoByCode('TS0248')
    const data2 = this.$getAlertInfoByCode('TS0249')
    this.text1 = data1.msgDesc.split('${phone}')
    this.text2 = data2.msgDesc
    this.phone = data1.remark
    // this.isChooseNum =  this.$route.query.isChooseNum?this.$route.query.isChooseNum:0;

  },
  watch:{
    GoodsList:{
      immediate: true,
      handler(val) {
        val.forEach(item=>{
          item.idEdit = false
          // let retailData = {
          //   cisCode:this.$store.state.user.userInfo.customer.cisCode,
          //   productId: item.productInfoId,
          //   refreshPrice: true,
          //   type: 3,
          // }
          // getRetailPrice(retailData).then(res=>{
          //   item.salePrice = this.$util.getFloat(res.data.price,2)
          //   item.retailPrice = this.$util.getFloat(res.data.price,2)
          //   this.$forceUpdate()
          // })
          checkRetailL({id:item.productInfoId}).then(res => {
            if(res.data && res.data.code == 0){
              const {type, money, bei, min, max, haveRetailL } = res.data;// 0不限制  1限制
              item.min = min;
              item.bei = bei;
              item.type = type;
              item.max = max;
              item.salePrice = this.$util.getFloat(Number(money),2)
              item.retailPrice = this.$util.getFloat(Number(money),2)
              item.haveRetailL = haveRetailL;
            }
          })
        })
      },
      deep:true
    },
    townCode:{
      immediate: true,
      handler(val) {
        this.arrivalTimeStr = ''
        this.startShowTime = ''
        if((this.trans=='502004' ||this.trans=='502008') &&this.townCode ){
          this.getFWF()
        }
      }
    },
    warehouseInfoId:{
      immediate: true,
      handler(val) {
        this.arrivalTimeStr = ''
        this.startShowTime = ''
        if((this.trans=='502004' ||this.trans=='502008') &&this.townCode ){
          this.getFWF()
        }
      }
    },
    trans:{
      immediate: true,
      handler(val) {
        this.arrivalTimeStr = ''
        this.startShowTime = ''
        if((this.trans=='502004' ||this.trans=='502008') &&this.townCode){
          this.getFWF()
        }
      }
    },
    isChooseNum:{
      handler:function(val,old) {
        if(this.GoodsList.length>0&&this.GoodsList[0].discountTypeName == '套购') {
          this.goodsNum = 0
          this.goodsVolume = 0
          this.goodsPrice = 0
          this.olnyNum = 0
          this.olnyPrice= 0
          this.brightNum= 0
          this.brightPrice= 0
          this.GoodsList.forEach(item=>{
            this.goodsNum += (item.packageNum * this.isChooseNum)
            this.goodsVolume += (item.packageNum* this.isChooseNum * item.volume)
            this.goodsPrice += (item.packageNum* this.isChooseNum * item.billPrice)
          })
           // 获取专供
          const zg = this.GoodsList.filter(el => el.productLabel&&el.productLabel.indexOf('14182273113') > -1)
         if(zg.length>0){
          zg.forEach(el=>{
            this.olnyNum+=(el.packageNum * this.isChooseNum)
            this.olnyPrice+=Number(this.isChooseNum) * Number(el.billPrice)*Number(el.packageNum);
          })
         }
         
          // 获取璀璨
          const cc = this.GoodsList.filter(el => el.productLabel&&el.productLabel.indexOf('15691143849') > -1)
          if(cc.length>0){
            cc.forEach(el=>{
              this.brightNum+=(el.packageNum * this.isChooseNum)
              this.brightPrice+=Number(this.isChooseNum) * Number(el.billPrice)*Number(el.packageNum);
            })
          }
          
        }
        if((this.trans=='502004' ||this.trans=='502008') &&this.townCode ){
          this.getFWF()
        }

      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    changePhoneNum() {
      this.phoneCon =  this.phoneCon.replace(/\D/g, '')
      if( this.phoneCon.trim().length == 11&&this.salesShopInfoId&&(this.trans=='502004'||this.trans=='502008')){
        let data = {
          phone: this.phoneCon,
          shopInfoId:this.salesShopInfoId
        }
        this.TipsNames = []
        getUserList(data).then(res=>{
          if(res.data.code == 0 && res.data.list && res.data.list.length > 0) {
            if(!this.contacts){
              this.contacts =  res.data.list[0].userName
            }
            this.SorceIds =  res.data.list[0].sourceId? res.data.list[0].sourceId:5007684
            this.sourceName =  res.data.list[0].sourceName?res.data.list[0].sourceName:'自然到访'
            this.showSorce = (this.SorceIds ==  res.data.list[0].sourceId)?true:false
            if(this.userTipsList.length>0){
              this.userTipsList.forEach(item=>{
                item.checked = false
                this.TipsNames = []
                this.TipsIds = []
              })
            }
            if( res.data.list[0].userLableList.length>0 && this.userTipsList.length > 0){
              this.userTipsList.forEach(item=>{
                res.data.list[0].userLableList.forEach(twoItem=>{
                  if(item.id == twoItem.labelId) {
                    item.checked = true
                    this.TipsIds.push(twoItem.labelId)
                    this.TipsNames.push(twoItem.labelName);
                  }
                })
              })
            }else {
              this.TipsIds=[]
              this.TipsNames=[]
              this.$nextTick(()=>{
                if(this.userTipsList.length>0){
                  this.userTipsList.forEach(item=>{
                    item.checked = false
                    if(item.id  == 5007817){
                      item.checked = true
                      this.TipsIds.push(item.id)
                      this.TipsNames.push(item.label);
                    }
                  })
                }
              })
              this.SorceIds = 5007684
              this.sourceName =  '自然到访'
              this.showSorce = false
              this.sexId = 1
            }
          } else {
            this.SorceIds = ''
            this.sourceName = ''
            this.sexId = 1
            this.addressHx = []
            this.showSorce = false
            this.TipsIds=[]
            this.TipsNames=[]
            this.$nextTick(()=>{
              if(this.userTipsList.length>0){
                this.userTipsList.forEach(item=>{
                  item.checked = false
                  if(item.id  == 5007817){
                    item.checked = true
                    this.TipsIds.push(item.id)
                    this.TipsNames.push(item.label);
                  }
                })
              }
            })
            this.SorceIds = 5007684
            this.sourceName =  '自然到访'
            this.showSorce = false
          }
        })
      }

    },

    // 查询服务费
    getFWF(){
      if( ! this.townCode){
        this.$message.warning('请选择省市区县')
        return
      }
      // if( !this.warehouseInfoId ){
      //   this.$message.warning('请选择统仓地址')
      //   return
      // }
      this.arrivalTimeStr = ''
      this.startShowTime = ''
      let orderServiceFeeItemDtoList = []
      this.GoodsList.forEach(item=>{
        orderServiceFeeItemDtoList.push({
          "productId": item.productInfoId,		//产品id
          "warehouseInfoId": "",			//仓库werks-lgort，例如2600-1532，dms代理商下单必传，其他情况传空
          "qty": this.activeType=='套购'?item.buyQtyCherk*this.isChooseNum:item.buyQtyCherk,
          zzprdmodel:item.productCode,
          orgId:this.orgId,
        })
      })
      let data = {
        "townCode":  this.townCode,
        "countyCode": this.districtCode,
        "cityCode":  this.cityCode,
        "provinceCode":  this.provinceCode ,
        "warehouseInfoId":  this.warehouseInfoId?this.warehouseInfoId:'',			//发货仓库id，信天翁代理商下单必填，信天翁分销商下单或者dms下单必须传空
        "shopId": '', // this.salesShopInfoId,			//门店id
        "documentSource":"1",			//dms下单传5，其他随便传个不是5的数就行
        "orderServiceFeeItemDtoList": orderServiceFeeItemDtoList,
        trans:this.trans
      }
      this.pageLoadFlag = true
      findMoneyBuyBegin(data).then(res => {
        if(res.data && res.data.data && res.data.data.timelinessDate){
          this.arrivalTimeStr =  res.data.data.timelinessDate?res.data.data.timelinessDate.replace(/-/g,"/"):''
          this.startShowTime = res.data.data.timelinessDate?res.data.data.timelinessDate.replace(/-/g,"/"):''
        } else{
          //  如果接口没返回数据 就默认当前日期加3天
          this.arrivalTimeStr =  this.$util.getDateSpecify(3)
          this.startShowTime = this.$util.getDateSpecify(3)
        }
        if(res.data.code == 0){
          this.serviceCharge = res.data.data.totalAmount
          this.orderServiceFeeItemDtoList = res.data.data.orderServiceFeeItemDtoList
          this.orderServiceFeeItemDtoList.forEach((itemFirst,indexFirst)=>{
            this.GoodsList.forEach((item,index)=>{
              if( itemFirst.productId == item.productInfoId ){
                this.$nextTick(()=> {
                  this.$set(item,'serviceMoney',itemFirst.serviceFee)
                })
              }
            })
          })
          this.pageLoadFlag = false
        }
        if(res.data.code == 1){
          this.pageLoadFlag = false
          this.serviceCharge = 0
          this.GoodsList.forEach((item,index)=>{
            this.$nextTick(()=> {
              this.$set(item,'serviceMoney','0.00')
            })
          })
        }
        // else  {
        //   this.pageLoadFlag = false
        //   this.serviceCharge = 0
        //   this.GoodsList.forEach((item,index)=>{
        //     item.serviceMoney = 0.00
        //   })
        // }
      }).catch(error=>{
        //  如果接口没返回数据 就默认当前日期加3天
        this.arrivalTimeStr =  this.$util.getDateSpecify(3)
        this.startShowTime = this.$util.getDateSpecify(3)
      })
    },
    changeSEX(e) {
      this.sexId = e.target.value;
    },
    changePrice(item){
      this.$forceUpdate()
    },
    openSource(){
      this.visibleaA = true
    },
    openTips(){
      this.visibleTips = true
    },
    // 获取用户来源
    getuserSorceList() {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findList(data).then(res => {
        if (res.data.code == 0) {
          this.userSorceList = res.data.data;
          if(this.userSorceList.length > 0) {
            this.userSorceList.forEach(item => {
              item.id = (item.id).toString();
              item.ischerk = false;
            });
          }

          this.pageLoadFlag = false;
        }
        this.pageLoadFlag = false;

      });
    },
    // 获取用户标签
    getuserTipList() {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findListLabel(data).then(res => {
        if (res.data.code == 0) {
          this.userTipsList = res.data.data;
          if (this.userTipsList.length > 0) {
            this.userTipsList.forEach(item => {
              item.checked = false;
            });
          }
          this.pageLoadFlag = false;

        }
        this.pageLoadFlag = false;
      });
    },
    // 点击
    changeSorceBtn(item) {
      if(this.SorceIds == item.id) {
        this.SorceIds = ''
        return
      }
      this.SorceIds = item.id;
      this.$forceUpdate();
    },
    changeBtnnew(item){
      this.SorceIds = item;
    },
    changeTipsBtn(item) {
      if (!item.checked) {
        if (this.TipsIds.length >= 8) {
          this.$message.warning("用户标签选择不能超过8个");
          return;
        }
      }
      item.checked = !item.checked;
      this.$forceUpdate();
      this.TipsIds = [];
      this.TipsNames = [];
      this.userTipsList.forEach(items => {
        if (items.checked == true) {
          this.TipsIds.push(items.id);
          this.TipsNames.push(items.label);
        }
      });
      this.$forceUpdate();
    },
    // 点击弹窗取消和确定的时候
    chengeOK(item,name) {
      this.TipsIds = [];
      this.TipsNames= [];
      this.TipsIds = item;
      this.TipsNames = name
      this.userTipsList.forEach(items=>{
        items.checked = false
        item.forEach(itemB=>{
          if(items.id == itemB) {
            items.checked = true
          }
        })
      })
    },
    // 用户来源点击确定 调接口 增加数据 成功的话赋值
    getReason(value,value2) {
      if (!value) {
        this.SorceIds = value2
        return;
      }
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        source: value,
        remark: ""
      };
      this.pageLoadFlag = true;
      saveInfo(data).then(res => {
        if (res.data.code == 0) {
          this.userSorceList.push(
            {
              id: res.data.data,
              source: value
            }
          );
          this.SorceIds = "";
          this.SorceIds = this.userSorceList[this.userSorceList.length - 1].id;
          this.$forceUpdate();
          this.pageLoadFlag = false;
        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }
      });
    },
    getReasonTip(value, value1) {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        label: value,
        remark: value1
      };
      saveInfoLabel(data).then(res => {
        if (res.data.code == 0) {
          this.userTipsList.push(
            {
              id: res.data.data,
              label: value,
              checked: true
            }
          );
          this.TipsIds.push(res.data.data);
          this.TipsNames.push(value);
          this.pageLoadFlag = false;

        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }
      });
    },
    disabledDate(current) {
      let nowDate = moment().format("YYYY-MM-DD")
      // return current < moment().startOf('days') || current > new Date(this.$util.getDate30())
      return current < moment(nowDate) || current > moment(nowDate + ' 23:59:59').add(29, 'd')
    },
    disabledDateS(current) {
      // let nowDate = moment().format("YYYY-MM-DD")
      // 目前这个值是写死的 后期需要对接口
      let three = moment(this.startShowTime)
      // return current < moment().startOf('days') || current > new Date(this.$util.getDate30())
      return current < moment(this.startShowTime) || current > three.add(29, 'd')
    },
    arrivalTimeFocus(){
      if(!this.arrivalTimeStr&& this.haveDeliveryFee ) {
        this.$message.warning('请先填选相关信息')
        return
      } else {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(){
      if(this.arrivalTimeStr){
        this.endOpen = true;
        return
      }
    },
    // 期望送货日期时间选择
    arrivalTimeChange(value,dateString) {
      this.arrivalTimeStr = dateString;
      this.endOpen = false
    },
    blurAddress(){
      if(!this.districtCode) {
        this.userDetailAddress = ''
        return
      }
    },
    // 获取下拉地址
    getAdress(value) {
      if(!this.districtCode) {
        this.$message.warning('请先选择省市区县')
        this.$refs.address.blur()
        this.userDetailAddress = ''
        return
      }
      this.userDetailAddress = value;
      if(!this.userDetailAddress ){
        this.companyList = []
        return
      }
      if(this.userDetailAddress  && this.userDetailAddress.length < 4 && this.userDetailAddress.length > 0){
        this.companyList = []
        return
      }
      let data  = {
        searchstr: this.userDetailAddress,
        region:this.districtCode
      }
      this.fetching = true;
      getAdressapi(data).then(res => {
        if (res.data.data!==null && res.data.data.pois && res.data.data.pois.length > 0 && res.data.data.pois !== null) {
          this.companyList = [...new Set(res.data.data.pois)]; //缓存列表数据，前端分页
        } else {
          this.companyList =[]
        }

        this.fetching = false;
      });

    },
    //查询数据
    search(vaule,e) {
    },
    async sumbitaaa() {
      this.isLoading = true
      this.isLoading = false
      this.orderSubmit()
      return
    },
    // 服务列表查询
    getService() {
      let data = {}
      serviceInformation(data).then(res => {
        this.FWlist = res.data
      })
    },
    // 销量所属门店查询
    getsaleShopList() {
      let data = {
        orgId: this.orgId,
        matklId: this.matklId
      }
      SalesShopInfoList(data).then(res => {
        this.saleShopList = res.data.list
        if (this.saleShopList.length > 0) {
          this.receiveShop = res.data.list[0].name;
          this.salesShopInfoId = res.data.list[0].code;
        }

      })
    },
    // 更改销量所属于门店
    handleChangeshopList(value, e) {
      this.receiveShop = value;
      this.salesShopInfoId = e.key;
      this.arrivalTimeStr = ''
      this.startShowTime = ''
      this.changePhoneNum()
    },
    // 在线充值
    saveMoneyOnline() {
      saveMoney().then(res => {
        this.saveMoneyValue = res.data;
        this.nextTick(() => {
          var selfOrderPay = document.getElementById("selfOrderPay");
          selfOrderPay.submit();
        })

      })
    },
    // 充值记录
    saveMoneyOnlineHis() {
      saveHisetory().then(res => {
        this.saveMoneyValue = res.data;
        this.$nextTick(() => {
          var selfOrderPayh = document.getElementById("selfOrderPayh");
          selfOrderPayh.submit();
        })

      })
    },
    dataChange(event) {
      if((this.trans=='502004' ||this.trans=='502008') &&this.townCode  ){
        this.getFWF()
      }
      this.goodsNum = 0
      this.goodsVolume = 0
      this.goodsPrice = 0
      let num = 0
      let volume = 0
      let amount = 0
      let rebate = 0
      this.olnyNum = 0
      this.olnyPrice= 0
      this.brightNum= 0
      this.brightPrice= 0
      for (const item of this.GoodsList) {
        // this.goodsNum += Number(item.buyQtyCherk);
        // this.goodsVolume += Number(item.buyQtyCherk) * Number(item.volume);
        // this.goodsPrice += item.buyQtyCherk * item.standardPrice;
        num += Number(item.buyQtyCherk);
        volume += Number(item.buyQtyCherk) * Number(item.volume);

        amount += item.buyQtyCherk * item.billPrice;
        //返利金额
        rebate += item.buyQtyCherk * item.billPrice * this.TotalPayment.rebate
      }
      // 获取专供
      const zg = this.GoodsList.filter(el => el.productLabel&&el.productLabel.indexOf('14182273113') > -1)
      if(zg.length>0){
        zg.forEach(el=>{
          this.olnyNum+=Number(el.buyQtyCherk) 
          this.olnyPrice+=Number(el.buyQtyCherk) * Number(el.billPrice);
        })
      }
      
      // 获取璀璨
      const cc = this.GoodsList.filter(el => el.productLabel&&el.productLabel.indexOf('15691143849') > -1)
      if(cc.length>0){
        cc.forEach(el=>{
          this.brightNum+=Number(el.buyQtyCherk) 
          this.brightPrice+=Number(el.buyQtyCherk) * Number(el.standardPrice);
        })
      }
      this.goodsNum = num;

      this.goodsVolume = this.$util.getFloat(volume, 3);
      this.goodsPrice = this.$util.getFloat(amount, 2);
      this.$forceUpdate();
    },
    // 购物车查询
    carList() {
      this.pageLoadFlag = true
      specialZone({
        userActId: this.RouterParams.userActId
      }).then(res => {
        this.pageLoadFlag = false
        this.activeType = res.data.activityList[0].discountTypeName
        this.quantityControl = res.data.activityList[0].quantityControl
        if (res.data.msg == '参与活动已结束') {
          this.$message.warning('活动已结束');
          this.$router.go('-1');
        }
        this.pageLoadFlag = false;
        // 组织编码 物料组编码
        this.matklId = res.data.matklId;
        this.orgId = res.data.orgId;
        // 判断是否是统仓
        this.shareFlag = res.data.shareFlag;
        // 时间选择
        // this.defaultTime = res.data.minData;
        // 截止时间默认选项
        this.startTime = res.data.maxEndDate;
        if (res.data.shareFlag == 'Y') {
          this.houseAddress = '统仓地址'
          // this.distributors = true
          this.officeHis = true;
          this.dissize = true
          // 统仓收货地址   下面有
          // this.orderDis = res.data.customerAddressDTOs;
          // this.receive = res.data.customerAddressDTOs[0].address;
          // this.receiveID = res.data.customerAddressDTOs[0].id;
          // 海信办事处地址
          this.officehis = res.data.office;
          if (this.officehis.length > 0) {
            this.officeCode = res.data.office[0].code;
            this.receivehis = res.data.office[0].name;
          }

          // getconnect(res.data.office[0].code);
          // 为统仓收货时 联系人不可修改
          this.TCInt = true;
          // orderAddress({
          //   sendToId: res.data.customerAddressDTOs[0].id
          // }).then(res => {

          //   this.contacts = res.data.contact == '无' ? '' : res.data.contact;
          //   this.phoneCon = res.data.phone == '无' ? '' : res.data.phone;
          // }).catch(err => {
          //   console.log(err);
          // })
        } else if (res.data.marketAddress) {
          // 分销商地址
          this.choseDistribution = res.data.custMarketModels
          // 当用户选择直配到分销商时
          // 获取分销商列表
          this.DisList = res.data.custMarketModels;
          // 分销商id
          if (this.DisList.length > 0) {
            this.distributionAdd.DISCODE = res.data.custMarketModels[0].customerInfoId;
            // 分销商名称 
            this.distrName = res.data.custMarketModels[0].customerInfoName
            this.DISADDPARAMS.customerId = res.data.custMarketModels[0].customerInfoId;

          }

          // 分销商地址id
          // this.distributionAdd.DISADD = res.data.custMarketModels[0].id;
          // this.getPeople(res.data.custMarketModels[0].id)
          // // 分销地址
          // this.distrAddress = res.data.customerAddressDTOs[0].address;
          // 预付比例
          // this.advancePayRate = res.data.advancePayRate;
          // 请求分销商 地址参数 orgId
          this.DISADDPARAMS.orgId = res.data.orgId; // 接口没有这个参数
          this.DISADDPARAMS.matklId = res.data.matklId;

        }
        //   // 是否可以使用返利
        this.tradeType = res.data.tradeType == '01' ? true : false;
        //  要求到货周

        //   // 点击提交按钮提交参数
        this.orgAndGroup = res.data.matklId;
        this.maxEndDate = res.data.maxDate;
        //   // end 点击提交按钮参数

        this.obj = res.data;
        // 配送方式
        // this.options = res.data.deliveryList;
        // 默认配送方式id
        // if (this.options.length > 0) {
        //   this.trans = res.data.deliveryList[0].id
        // }

        // 开票户头
        this.billToDTOs = res.data.billToDTOs;
        // 开票户头ID 及 公司名称
        if (this.billToDTOs.length > 0) {
          this.invoice = this.billToDTOs[0].drawerName;
          this.invoiceId = this.billToDTOs[0].id;
        }
        
        // 售达地址
        this.orderDis = res.data.customerAddressDTOs;
        if (this.orderDis.length > 0) {
          this.receive = res.data.customerAddressDTOs[0].address;
          this.receiveID = res.data.customerAddressDTOs[0].id;
          //  初始化获取手机号
          this.getPeople(res.data.customerAddressDTOs[0].id)
          this.warehouseInfoId =  res.data.customerAddressDTOs[0].shareWarehouseId;

        }

        if (res.data.activityList.length > 0) {
          this.suppilers = res.data.activityList[0].fwOrgName + '-' + res.data.activityList[0].matklName;
        }

        this.GoodsList = res.data.activityList;

        if(  this.activeType == '套购'){
          this.isChooseNum = res.data.activityList&& res.data.activityList.length>0? this.GoodsList[0].buyQty/this.GoodsList[0].packageNum:0
          this.isChooseNumStart = res.data.activityList&& res.data.activityList.length>0? this.GoodsList[0].buyQty/this.GoodsList[0].packageNum:0
        }
        // 获取配送方式
        this.$nextTick(()=>{
          const itema = [{
            orgId: this.orgId,
            matklCode: this.GoodsList[0].matklCode,
            productIds: this.GoodsList.map(el => {
              return {
                id: el.productInfoId
              }
            })
          }]
          let data = {
            orderType:5,
            // serviceCode: 'cisFxZsyhGetDeliveryMethod',
            code:this.$store.state.user.userInfo.customer.cisCode,
            fxCustCisCode: this.$store.state.user.userInfo.customer.cisCode,
            dlCustCisCode:this.$store.state.user.userInfo.customer.cisCode,
            items: itema,
          }
          fxZsyhGetDeliveryMethod(data).then(res=>{
            if(res.data.code == 0) {
              this.options = res.data.data.items
              this.isShowLogisticsIcon = this.options.some(el => ['04', '08'].includes(el.code))
              // 获取接口中check == true 默认选中的下标
              this.transDefault = this.options.findIndex(el => el.check == true)>-1?this.options.findIndex(el => el.check == true):''
              // 赋值配送方式
              this.trans = this.options[this.transDefault].id
              // 是否展示服务费 根据配送方式中字段判断 // 0否，1是
              this.haveDeliveryFee = this.options[this.transDefault].haveDeliveryFee == 1? true : false
              this.$forceUpdate()
            }
          })
        })
        if (res.data.activityList.length > 0) {
          res.data.activityList.forEach(item => {
            if(this.activeType !== '套购'){
              item.buyQtyCherk = item.buyQty
            } else {
              item.buyQtyCherk = item.packageNum
            }
          })
        }

        this.TotalPayment = res.data.balanceDTO;
        //商品件数 体积合计 合计金额
        let num = 0,
          volume = 0,
          amount = 0;
        //返利金额 列表计算返利比例后的返利金额 与 this.TotalPayment.rebateAccount返利金额相比 取较小值
        let rebate = 0;
        for (const item of this.GoodsList) {
          num += Number(item.buyQty);
          volume += Number(item.buyQty) * Number(item.volume);

          amount += item.buyQty * item.billPrice;
          //返利金额
          rebate += item.buyQty * item.billPrice * this.TotalPayment.rebate
        };
        // 获取专供
        const zg = this.GoodsList.filter(el => el.productLabel&&el.productLabel.indexOf('14182273113') > -1)
        if(zg.length>0){
          zg.forEach(el=>{
            this.olnyNum+=Number(el.buyQty) 
            this.olnyPrice+=Number(el.buyQty) * Number(el.billPrice);
          })
        }
       
        // 获取璀璨
        const cc = this.GoodsList.filter(el => el.productLabel&&el.productLabel.indexOf('15691143849') > -1)
        if(cc.length>0){
          cc.forEach(el=>{
            this.brightNum+=Number(el.buyQty) 
            this.brightPrice+=Number(el.buyQty) * Number(el.billPrice);
          })
        }
        
        this.goodsNum = num;

        this.goodsVolume = this.$util.getFloat(volume, 3);
        this.goodsPrice = this.$util.getFloat(amount, 2);
        // 支付数据

        this.totalAdvanceMoney = res.data.balanceDTO.totalAdvanceMoney;
        this.TotalPayment.rebate = this.TotalPayment.rebate < rebate ? this.TotalPayment.rebate : this.$util.getFloat(rebate, 2);
        // this.goodsPrice = res.data.balanceDTO.totalMoney
        this.getService()
        this.getsaleShopList()
        this.getRebateData()
        if(this.orderDis.length > 0) {
          // 筛选出来当前选择的数据
          let obj = this.orderDis.find((i)=>{//listData就是上面的数据源
            return i.id === res.data.customerAddressDTOs[0].id;//筛选出匹配数据
          });

        }
      }).catch(err => {
        this.pageLoadFlag = false
        console.log(err);
      })
    },
    getRebateData(){
      this.GoodsList.forEach((item,index) => {
        try {
          ctsApi({
            serviceCode: "agentDirectDealerReturnPolicy",
            bigContractNo: "",
            companyCode: item.fwOrgCode,
            contractNo: "",
            distributorsCisCode: "",
            dlMdmCode: this.$store.state.user.userInfo.customer.customerCode,
            model: item.modelSap || item.productName,
            pricingGroup: item.priceGroupCode,
            tableRow: "",
            isActivityArea: true
          }).then(res=>{
            if(res && res.data && res.data.data && res.data.data.model){
              item.isShowRebate = true;
              item.returnAmount = res.data.data.returnAmount;
              item.upperLimit = res.data.data.upperLimit;
              this.$set(this.GoodsList[index], index, item)
            }
            if(index == this.GoodsList.length - 1){
              this.changeData()
            }
          })
        } catch (error) {
          if(index == this.GoodsList1.length - 1){
            this.changeData()
          }
        }
      })
    },
    changeData() {
      var map = {},
        dest = [];
      for (var i = 0; i < this.GoodsList.length; i++) {
        var ai = this.GoodsList[i];
        if (!map[ai.productGroup]) {
          dest.push({
            productGroup: ai.productGroup,
            name: ai.productGroupRemark,
            num: ai.packageNum,
            data: [ai]
          });
          map[ai.productGroup] = ai;
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j];
            if (dj.productGroup == ai.productGroup) {
              dj.data.push(ai);
              break;
            }
          }
        }
      }
      this.dataArr = dest;
    },
    // 子组件选择商品
    // 查询库存数
    stock(goodsData, i) {
      getNumber({
        code: goodsData.code,
        orgId: goodsData.orgId
      }).then(resD => {
        var newObject = Object.assign(this.GoodsList[i], resD.data[i])
        this.GoodsList[i] = newObject;

      })
    },

    onAddressChange(addressValArr) {
      // 老地址
      let oldAdress = this.districtCode
      // 长度如果等于2 乡镇就置空
      if(addressValArr&&addressValArr.length == 2) {
        this.districtCode = '';
      }
      // 长度如果等于3 区县就置空
      if(addressValArr&&addressValArr.length == 3) {
        this.townCode = '';
      }
      if(addressValArr.length > 0) {
        addressValArr.forEach((item, index) => {
          if (index == 0) {
            this.provinceCode = item;
          }
          if (index == 1) {
            this.cityCode = item;
          }
          if (index == 2) {
            this.districtCode = item;
          }
          if (index == 3) {
            this.townCode = item;
          }
        });
      } else {
        this.provinceCode = ''
        this.cityCode = ''
        this.districtCode = ''
        this.townCode = ''
        this.arrivalTimeStr = ''
        this.startShowTime = ''
      }
      // 如果之前的三级地址修改了  就清空详细地址
      if(oldAdress !=  this.districtCode ){
        this.userDetailAddress = ''
      }
      this.companyList = []
    },
    // 城市选择
    handleCityChange() {
      this.districtCode = '';
      this.townCode = '';
      this.getTowns(this.cityCode)
    },
    // 城镇选择
    handleTownChange() {
      this.townCode = '';
      this.getqu(this.districtCode);
    },
    // 城镇下一级 选择
    handleZhenChange() {
    },
    showModal() {
      this.visible = true;
    },
    handleOk(e) {

      this.visible = false;
    },
    moreBill() {

    },
    onGiveType(e) {
      this.trans = this.options[e.target.value].id;
      // 是否展示服务费 根据配送方式中返回字段判断 // 0否，1是
      this.haveDeliveryFee = this.options[e.target.value].haveDeliveryFee == 1? true : false
      if(this.trans !='502004' && this.trans != '502008'){
        this.provinceCode = ''
        this.cityCode = ''
        this.districtCode = ''
        this.townCode = ''
      }
      this.arrivalTimeStr = ''
      this.startShowTime = ''

      // if (this.houseAddress != '统仓地址') {
      // if (e.target.value == 3) {
      // 修改判断如果选择选项为直配到用户
      if (this.trans == 502004) {
        this.distributors = false;
        this.userAdderss = true;
        // 选择直配到用户 可以编辑联系人 和联系方式 其余的不可以
        this.TCInt = false
        this.getPeople(this.receiveID)
      }else if (this.trans == 502008) {
        this.distributors = false;
        this.userAdderss = true;
        this.TCInt = false
        this.changePhoneNum()
      } else if (this.trans == 502005) {
        if (!this.DisList || this.DisList.length == 0) {
          this.$warning({
            content: '暂无可选分销商，请选择其他配送方式',
          });
          return
        }
        this.distributors = true;
        this.userAdderss = false
        // 分销商 分销地址 获取联系人
        this.getDISADDList();
        this.getPeople(this.distributionAdd.DISCODE)
        this.TCInt = true
      } else {
        this.getPeople(this.receiveID)
        this.distributors = false;
        this.userAdderss = false;
        this.TCInt = true
      }
      // }
    },
    onSubmit() { //form
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          alert('submit!');
        } else {

          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 点击选择开票户头
    choseAccount() {
      // axios请求后台开票户头
      this.accountShow = !this.accountShow;
    },
    // 开票户头相关函数
    handleChange(value, e) {
      this.invoiceId = e.data.key
      this.invoice = value;
      this.accountShow = !this.accountShow;
    },
    handleBlur() { },
    handleFocus() { },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 用户级联选择改变
    cityChange(value) {

    },
    // 判断组合购是否符合 条件
    checkCombinationPurchase(proItem) {
      // console.log('proItem', proItem);
      let standardValArr = [] // 每个组实际要求数量
      let currValArr = [] // 每个组购买总数
      let multipleArr = [] // 每个组购买总数与实际要求数量成比例
      // proItem.dataArr.forEach((aaitem) => {
      if (this.GoodsList[0].discountTypeName == '组合购') {
        proItem.forEach(val => {
          val.numOrder = 0
          val.data.forEach(item => {
            val.numOrder += Number(item.buyQtyCherk)

          })
          standardValArr.push(val.num)
          currValArr.push(val.numOrder)
          multipleArr.push(val.numOrder % val.num)
            ;
        })
      } else {
        proItem.forEach(val => {
          val.numOrder = 0
          val.numOrder += Number(val.buyQtyCherk)
          standardValArr.push(val.packageNum)
          currValArr.push(val.numOrder)
          multipleArr.push(val.numOrder % val.packageNum)
        })
      }
      // })
      for (let i = 0; i < currValArr.length; i++) {
        if (currValArr[i] == 0) {
          return false
        }
      }
      for (let i = 0; i < multipleArr.length; i++) {
        if (multipleArr[i] != 0) {
          return false
        }
      }
      let multiple = currValArr[0] / standardValArr[0] //先生成一个参考比例
      // 判断每组购买总数比例是否与实际要求比例相同
      for (let i = 0; i < currValArr.length; i++) {
        if (currValArr[i] / standardValArr[i] != multiple) {
          return false
        }
      }
      return true
    },
    //是否返利点击
    tradeOnChange(e) {
      this.isTradeChecked = e.target.checked;
      this.$forceUpdate()
    },
    // 直送用户/送装一体地区校验
    deliveryAddressCheck() {
      const orgparamsList = this.GoodsList.map(el => {
        return {
          orgId: this.orgId,
          matklId: el.matklCode
        }
      })
      let data = {
        "serviceCode":"cisDeliveryAddressCheck",
        "provinceCode":this.provinceCode,
        "cityCode":this.cityCode,
        "districtCode":this.districtCode,
        "townCode":this.townCode,
        "custId":this.$store.state.user.userInfo.customer.id,
        orgparamsList:orgparamsList,
        orderType:1,
        deliveryMethod:this.trans=='502004'?'04':'08',
        haveDeliveryFee:this.haveDeliveryFee?1:0,// 是否展示服务费
      }
      return ctsApi(data)
    },
    async isCheckShipTo(){
      let res = await checkShipTo({shipToId:this.receiveID })
      console.log('res22',res)
      return res.data.data.isInvalidRegion
    },
    getAddress(e) {
      console.log('eeeee',e)
      this.newTown = e[3]
      console.log('this.newTown ',this.newTown )
    },
    // 点击提交订单按钮
    async orderSubmit() {
      if(!this.trans){
        this.$message.warning('请选择配送方式')
        return false
      }
      if(['502001', '502002'].includes(this.trans) && this.houseAddress == '收货地址' && !this.newTown) {
        let checkRes= await this.isCheckShipTo()
        if(checkRes == 1) {
          // 上面不符合条件的弹出弹窗
          this.visibleAddress = true
          return
        }
      }
      if(['502004', '502008'].includes(this.trans)) {
        // 判断下单产品是否支持直送用户
        const index1 = this.options.findIndex(el => el.code == '04')
        if(['502004'].includes(this.trans) && this.options[index1].msg) {
          this.$message.warning(this.options[index1].msg)
          return false
        }
        // 判断下单产品是否支持送装一体
        const index2 = this.options.findIndex(el => el.code == '08')
        if(['502008'].includes(this.trans) && this.options[index2].msg) {
          this.$message.warning(this.options[index2].msg)
          return false
        }
        if(!this.townCode) {
          this.$message.warning('请选择收货地区')
          return false
        }
        if(this.userDetailAddress == '' ){
          this.$message.warning('请填写详细地址');
          this.isLoading = false
          return;
        }
        if(!this.salesShopInfoId) {
          this.$message.warning('请选择销量所属门店')
          return false
        }
        if(this.TipsIds.length == 0) {
          this.$message.warning('请选择用户标签')
          return false
        }
        if(this.SorceIds == '') {
          this.$message.warning('请选择用户来源')
          return false
        }
        const res =  await this.deliveryAddressCheck()
        if(res.data.code == 1) {
          this.$message.warning(res.data.msg)
          this.isLoading = false
          return;
        }
      }
      if( this.trans== '502004' || this.trans== '502008') {
        // 判断零售价格在建议零售价0.5倍到1倍之间。
        let arr = this.GoodsList
        for(let i = 0; i < arr.length; i++){
          if (!arr[i].salePrice || arr[i].salePrice==0) {
            this.$message.warning(`请输入第${i+1}件商品的零售价格`)
            this.isLoading = false
            return false
          }
          // if ( Number(arr[i].retailPrice) && (( Number(arr[i].salePrice) <  Number(arr[i].retailPrice * 0.5) ) || (  Number(arr[i].salePrice) >  Number(arr[i].retailPrice * 1) ))){
          //   this.$message.warning(`建议零售价为${arr[i].retailPrice}元，可填范围在0.5倍到1倍之间。`)
          //   this.isLoading = false
          //   return false
          // }
          if (arr[i].type == 1 && arr[i].salePrice) {
            if (Number(arr[i].salePrice) < arr[i].min || Number(arr[i].salePrice) > arr[i].max) {
              if (arr[i].haveRetailL) {
                this.$message.warning(`请在默认建议零售价的${arr[i].bei}倍范围内录入价格`);
              } else {
                this.$message.warning(`您录入的价格不符合产品公司规定，请按要求录入。`);
              }
              return;
            }
          }
        }

      }

      if (this.startTime == '') {
        this.$message.warning('请填写截止日期');
        this.isLoading = false
        return;
      }
      if (!this.orderDis || this.orderDis.length == 0) {
        this.$message.warning('暂无可选收货地址，无法提交');
        this.isLoading = false
        return;
      }
      if (this.receiveID == '') {
        this.$message.warning('请填写收货地址');
        this.isLoading = false
        return;
      }
      if (this.userAdderss) {
        if (!(this.districtCode && this.townCode)) {
          this.$message.warning('请选择直配地址');
          this.isLoading = false
          return;
        }
        if (this.userDetailAddress == '') {
          this.$message.warning('请填写详细直配地址');
          this.isLoading = false
          return;
        }
      }
      if (!this.contacts) {
        this.$message.warning('请填写联系人信息');
        this.isLoading = false
        return;
      }
      if (!this.phoneCon) {
        this.$message.warning('请填写手机号');
        this.isLoading = false
        return;
      }
      if (this.phoneCon.trim().length !== 11) {
        this.$message.warning("手机号不正确，请重新填写");
        return;
      }
      if (!(/^1[3456789]\d{9}$/.test(this.phoneCon))) {
        this.$message.warning("手机号码有误，请重填");
        this.isLoading = false
        return;
      }
      if (this.goodsPrice > this.TotalPayment.canUseMoney) {
        this.$message.warning('订单金额不得大于账户可用余额');
        this.isLoading = false
        return;
      }

      let saleId = ''
      if (this.trans == '502004'||this.trans == '502008') {
        saleId = this.salesShopInfoId
      } else {
        saleId = ''
      }
      if (this.GoodsList[0].discountTypeName == '组合购') {
        let isTrue = this.checkCombinationPurchase(this.dataArr)
        let productGroupRemark = [], packageNumArr = [];
        this.dataArr.forEach(el => {
          productGroupRemark.push(el.name)
          packageNumArr.push(el.num)
        })
        if( this.quantityControl == 1){
          let isMeetProportion = false
          isMeetProportion = this.dataArr.every(el => el.numOrder >= el.num)
          if(!isMeetProportion ) {
            this.$message.warning(`该组合活动的 ${productGroupRemark.join('和')}的数量的起订量分别为${packageNumArr.join('和')}`)
            return
          }
        }else{
          if (!isTrue) {
            this.$message.warning(`该组合活动的${productGroupRemark.join('和')}的数量需要满足${packageNumArr.join(':')}的比例`);
            // this.$message.warning('下单数量不满足组合购比例,请重新下单')
            this.isLoading = false
            return
          }
        }
      }
      if (this.GoodsList[0].discountTypeName == '套购') {
        let isTrue = this.checkCombinationPurchase(this.GoodsList)
        if (!isTrue) {
          this.$message.warning('下单数量不满足套购比例,请重新下单')
          this.isLoading = false
          return
        }
      }
      let buyNums = []
      if(this.activeType=='套购'){
       this.GoodsList.forEach(el=>{
         el.buyNum = this.isChooseNum * el.packageNum
       })
       buyNums = this.GoodsList.map(it => it.buyNum).join(',')
      } else {
        buyNums = this.GoodsList.map(it => it.buyQtyCherk).join(',')
      }
      const userActId = this.GoodsList.map(it => it.id).join(',')
      // const buyNums = this.GoodsList.map(it => it.buyQtyCherk).join(',')
      const userActList = this.GoodsList.map(el => {
        return {
          userActId: String(el.id),
          retailPrice:el.salePrice,
        }
      })
      this.isLoading = true;
      let data = {
        haveDeliveryFee:this.haveDeliveryFee?1:0,// 是否展示服务费
        userActList:JSON.stringify(userActList),
        userActId: userActId,
        //供应商和物料组
        orgAndGroup: this.obj.orgId + '-' + this.obj.matklId,
        id: this.RouterParams.userActId || '',
        qty: buyNums,
        // 送达方 商家id 开票
        billTo: this.invoiceId,
        prdIds: this.RouterParams.prdIds,
        buyNums: buyNums,
        // 商家名字
        address1: this.receiveID,
        arrivalTimeStr:this.arrivalTimeStr&&this.haveDeliveryFee?this.arrivalTimeStr.replace(/\//g,"-")+' 23:59:59':'',
        trans: this.trans,
        contact: this.contacts,
        mobile: this.phoneCon,
        endDate: this.startTime,
        maxEndDate: this.obj.maxEndDate,
        orderRebate: this.isTradeChecked ? "Y" : "N",
        // 客户采购单号
        orderCode: this.orderNumber,
        // 用户手填地址
        address3: (this.trans==502004||this.trans==502008)?this.userDetailAddress:'',
        // // 分销商地址 id
        address4: this.distributors ? this.distributionAdd.DISADD : '',
        // plListStr: JSON.stringify(this.submitGoods)
        actCode: this.obj.actCode,
        officeId: this.officeCode,
        salesShopInfoId: saleId,
        // 服务列表
        serviceTypeCodes: this.fwlbId.join(','),
        district: this.userAdderss ? this.districtCode : '',
        town: this.userAdderss ? this.townCode : '',
        gender:(this.trans != '502004'&&this.trans != '502008') ? '' : this.sexId,// 性别
        sourceId:(this.trans != '502004'&&this.trans != '502008') ? '' :this.SorceIds,// 用户来源
        labelList:(this.trans != '502004'&&this.trans != '502008') ? '' :this.TipsNames.join(','), // 用户标签
        validTown:(this.trans == '502001'||this.trans == '502002')&& this.houseAddress == '收货地址'?this.newTown:''

      }
      specialSubmit(data).then(res => {
        // var orderList = JSON.stringify(res.data.list);
        if (res.data.code == 1) {
          this.$message.warning(res.data.msg)
          this.isLoading = false;
          return;
        }else if(res.data.code == 400){
          this.isLoading = false;
          return;
        } else {
          if(res.data.serviceFeeCode) {
            this.$router.push({
              path: "/serviceFeeModule/pay",
              query: {
                id:res.data.serviceFeeCode,
                batch:0,
                b2bOrderCode: res.data.b2bOrderCode,
                orgName: '订单号'
              }
            });
            return
          }
          this.$router.push({
            path: '/confirm/confirmsuccess',
            query: {
              b2bOrderCode: res.data.b2bOrderCode,
              orgName: '订单号'
            }
          })
        }
      })
    },
    // 时间更改
    handleTimeChange(e, defaultTime) {

      this.startTime = defaultTime;
    },
    // // 控制可选时间范围
    // disabledDate(current) {
    //   return current < new Date(this.obj.minDate + ' 00:00:00') || current >= new Date(this.obj.maxEndDate)
    // },
    // 获取子组件选中商品
    sonCheckList(data) {

      let cheacked = this.sonChectItme.indexOf(data)
      if (this.sonChectItme.indexOf(data) == -1) {
        this.sonChectItme.push(data);
      } else {
        this.sonChectItme.splice(cheacked, 1)
      }
    },
    //   点击返回购物车
    goCar() {
      this.$router.go('-1');
    },
    // 收货地址点击选择
    receiveAddress() {
      this.receiveAdd = !this.receiveAdd;
    },
    // 收货地址下拉框选择
    handleChangeAddress(value, e) {
      // 筛选出来当前选择的数据
      let obj = this.orderDis.find((i)=>{
        return i.id === e.key;//筛选出匹配数据
      });
      
      this.receive = value;
      // 收货地址 选中 id
      this.receiveID = e.key;
      this.warehouseInfoId = obj.shareWarehouseId;
      this.SAddressChange(e.key)
      this.receiveAdd = !this.receiveAdd;
      // 获取手机号
      this.getPeople(e.key)
    },
    // 海信办事处
    hishandleChangeAddress(value, e) {
      this.receivehis = value;
      this.officeCode = e.key;
      this.hisenseOff = !this.hisenseOff;
    },
    // 切换收货地址
    SAddressChange(id) {
      // showAnother({
      //   id
      // }).then(res => {
      //   console.log(res)
      // }).catch(err => {
      //   console.log(err)
      // })
    },
    // 分销商显示隐藏
    choseDistr() {
      this.distr = !this.distr;
    },
    // 切换分销商
    handleChangeDistr(value, e) {
      this.distrName = value;

      this.DISADDPARAMS.customerId = e.key;
      this.distributionAdd.DISCODE = e.key;
      this.getDISADDList()
    },
    // 分销商切换获取分销商地址list
    getDISADDList() {
      let data = this.DISADDPARAMS;
      getDISAdd(data).then(res => {

        this.choseDistribution = res.data;
        this.distrAddress = res.data[0].address;
        this.distributionAdd.DISADD = res.data[0].id;
        this.getPeople(res.data[0].id)
      }).catch(err => {
        console.log(err);
      })
    },
    distrAddShow() {
      this.distrAdd = !this.distrAdd;
    },
    // 选择经销商地址
    handleChangeDistrAdd(value, e) {
      this.distributionAdd.DISADD = e.key;
      this.distrAddress = value;
      this.getPeople(e.key);
    },
    // 分销商地址切换获取联系人
    // 获取手机号
    getPeople(sendToId) {
      getPhone({
        sendToId
      }).then(res => {

        this.contacts = res.data.contact == "无" ? '' : res.data.contact;
        this.phoneCon = res.data.phone == "无" ? '' : res.data.phone;
        this.changePhoneNum()
      })
    },
    addItem() {

      //this.items.push(`New item ${index++}`);
    },
    // 监听输入变化
    handleChangeInt(e) {

    },
    // 弹框
    ToDetail() {
      this.modelShow = true;
      var data = {
        orgIdParam: this.orgId,
        matklIdParam: this.matklId,
        weekName: this.selected,
        purchaseType: this.RouterParams.purchaseType,
        pageNo:1,
        pageSize:10000,
      }
      quotaDeatail(data).then(res => {

        this.pageLoadFlagTable = false;
        this.dataAll =  res.data.dataList.list;
        this.data =  res.data.dataList.list.slice(0, 10)
        this.pager.count = res.data.dataList.list.length;
      })
    },
    // 分页
    // 分页
    pagesiezes(data) {

      // var prev = data - 1;
      var start = (data - 1) * this.pager.pageSize;
      this.data = this.dataAll.slice(start, start + this.pager.pageSize)
      this.pager.pageSize;

    },
    // handleWeek(value, e) {

    //   this.endTime = e.key;
    //   this.selected = value
    // },
    // 海信办事处
    hisenseOffice() {
      this.hisenseOff = !this.hisenseOff;
    },
    // 智能识别
    recognitionFun(res){
      const townCode = res.levelAddress.town_code;
      // 收货地址
      this.addressDftVal = [townCode.slice(0,2).padEnd(12, 0),townCode.slice(0,4).padEnd(12, 0),townCode.slice(0,6).padEnd(12, 0),townCode.padEnd(12, 0)];
      // 收货地址 code
      this.provinceCode = townCode.slice(0,2).padEnd(12, 0);
      this.cityCode = townCode.slice(0,4).padEnd(12, 0);
      this.districtCode = townCode.slice(0,6).padEnd(12, 0);
      this.townCode = townCode.padEnd(12, 0);
      // 详细地址
      this.userDetailAddress = res.address;
      // 姓名
      this.contacts = res.userName;
      // 电话
      this.phoneCon = res.mobile;
    }
  }
}